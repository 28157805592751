.teaser-webinar {
  @extend .row;
  margin-bottom: 50px;
  position: relative;

  .teaser-webinar-hero {
    @include media-breakpoint-down(sm) {
     @include make-col(12);
     height: auto;
     padding: 20px 55px 20px 30px;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include make-col(4);
    background: url('../Images/layout/startseite-gruene-flaeche@2x.png') no-repeat;
    height: auto;
    min-height: 265px;
    padding: 40px 55px 20px 60px;

    .teaser-webinar-label {
      margin-left: 0;
      color: #D8D8DA;
      margin-top: 0;
      margin-bottom: 0;
      font-family: $font-family-light;
      font-size: 17px;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      height: 20px;
    }

    .teaser-webinar-name {
      @include media-breakpoint-down(sm) {
        margin: 15px 0 20px 0;
      }
      font-family: $font-family-medium;
      color: $color-white;
      margin-top: 0;
      font-size: 24px;
      line-height: 28px;
    }

    .teaser-webinar-date {
      font-family: $font-family-medium;
      color: $color-white;
      align-self: flex-end;
      margin-bottom: 0;
      line-height: 1.5rem;
    }

    .teaser-webinar-time {
      font-family: $font-family-light;
      color: $color-white;
      align-self: flex-end;
    }
  }

  .teaser-webinar-content {
    @include media-breakpoint-down(sm) {
     @include make-col(12);
     padding: 20px 30px 20px 30px;
     height: auto;
    }
    display: flex;
    flex-wrap: wrap;

    @include make-col(8);
    box-shadow: 3px 0px 6px $color-gray-light;
    padding: 25px 15px 20px 40px;

    .teaser-webinar-title {
      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }

      h3 {
        margin-top: 0;
        font-family: $font-family-medium;
        color: $brand-primary;
        font-size: $font-size-base * 1.1;
      }

      h4 {
        @include make-col(11);
        margin-top: 5px;
        margin-left: 0;
        font-family: $font-family-light;
        color: $color-gray-medium;
        font-size: $font-size-base * 1.1;
      }
    }
  }

  .teaser-webinar-duration {
    @include media-breakpoint-down(sm) {
      @include make-col(3);
      background-position: right center;
      height: 120px;
      margin-right: 0px;
    }
    padding-right: 45px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include make-col(2);

    &::after {
      content: ' ';
      position: absolute;
      right: 0;
      background: url('../Images/layout/arrow-webinar.svg') no-repeat;
      background-position: right 5px center;
      width: 60px;
      height: 140px;

      @include media-breakpoint-up(lg) {
        background-position: right 25px center;
      }
    }

    .duration-value {
      color: $color-gray-medium;
      font-size: $font-size-extralarge * 1.3;
      line-height: $font-size-extralarge;
      font-family: $font-family-medium;
      width: 70px;
      display: block;
      justify-content: center;
      text-align: center;
    }

    .duration-label {
      display: flex;
      line-height: 1.2rem;
      font-size: 1rem;
      color: $color-gray-medium;
      width: 70px;
      justify-content: center;
      text-align: center;
    }
  }

  .teaser-webinar-description {
    @include media-breakpoint-down(sm) {
      @include make-col(8);
      line-height: 1.6rem;
      margin-left: 15px;
    }
    @include make-col(7);
  }

  .teaser-webinar-register {
    @include media-breakpoint-down(sm) {
      position: unset;
      margin: 0 auto;
      margin-top: 25px;
    }
    position: absolute;
    bottom: 25px;
    right: 15px;
  }
}
