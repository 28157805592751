.teaser-events {
  @extend .row;
  margin-bottom: 50px;
  position: relative;
  margin-left: 0;
  margin-right: 0;

  .teaser-events-hero {
    @include media-breakpoint-down(md) {
     @include make-col(12);
     height: auto;
     padding: 15px;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include make-col(8);
    background: url('../Images/layout/startseite-gruene-flaeche@2x.png') no-repeat;
    padding:  25px 34px 25px 30px;

    ul {
        @include make-col(12);
        width: 100%;
        list-style: none;
        // display: inline;
        li {
            color: white;
            width: 100%;
            margin-bottom: 7px;

            &::before {
                content: "\25AA";
                // color: $color-orange;
                font-weight: bold;
                display: inline-block;
                width: 16px;
                margin-left: -16px;
            }
        }
    }

    .teaser-events-name {
      @include media-breakpoint-down(sm) {
        margin: 15px 0 20px 0;
      }
    }

    h3{
        margin-top: 0;
        font-family: $font-family-light;
        color: $color-white;
        margin-top: 0;
        font-size: 25px;
        line-height: 30px;
        width: 100%;
        height: auto;
    }

    p {
        color: white;
    }
  }

  .teaser-events-content {
    @include media-breakpoint-down(md) {
     @include make-col(12);
     padding: 15px;
     height: auto;
    }
    display: flex;
    flex-wrap: wrap;
    min-height: 270px;
    @include make-col(4);
    background-color: #4B575C;
    padding:  25px 30px 35px 30px;

    .teaser-events-title {
      @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
      }

      h3 {
        margin-top: 0;
        font-family: $font-family-medium;
        color: $brand-primary;
        font-size: $font-size-base * 1.1;
      }

      h4 {
        margin-top: 5px;
        margin-left: 0;
        font-family: $font-family-medium;
        color: white;
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 5px;
      }

      p {
          color: white;
          line-height: 25px;

          &:last-child {
            font-family: $font-family-medium;
            font-size: 25px;
            margin-bottom: 14px;
          }
      }

      .date {
        margin-bottom: 25px;
      }

      figure {
          margin-bottom: 30px;

        img {
            width: 74%;
        }
      }
    }
  }

  .teaser-events-description {
    @include media-breakpoint-down(sm) {
      @include make-col(8);
      line-height: 1.6rem;
      margin-left: 15px;
    }
    @include make-col(7);
  }

  .teaser-events-register {
    @include media-breakpoint-down(sm) {
      position: unset;
      margin: 0 auto;
      margin-top: 25px;
    }

    a{
        text-transform: uppercase;
    }

    margin-top: 14px;
  }
}
