.grid-content {
  @include media-breakpoint-down(sm){
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 15px;
  }
  @extend .container-fluid;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 30px;

  &:first-of-type {
    @include media-breakpoint-down(sm){
      padding: 0 15px 15px 15px;
    }
    margin-top: 35px;
    padding: 0 30px 15px 30px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.background-gray {
    @include media-breakpoint-down(sm){
      padding: 35px 15px;
    }
    background-color: $color-gray-light;
    padding: 30px;
  }

  &.background-darker-gray {
    @include media-breakpoint-down(sm){
      padding: 35px 15px;
    }
    
    background-color: #cecdcd;
    padding: 30px;
  }

  &.background-dark-gray {
    background-color: #4B575C;

    h2 {
        color: white;
    }
  }
  
  &.background-green {
    background: url('../Images/layout/startseite-gruene-flaeche@2x.png') no-repeat;
    height: auto;
    background-size: cover;
    min-height: 265px;
    color: white;
    
    @include media-breakpoint-down(sm) {
      padding: 15px 15px 30px;
    }
    
    .neos-nodetypes-headline {
      h1, h2, h3, h4 {
        color: white;
      }
    }
  }

  &.check-expert-tool {
    @include media-breakpoint-down(sm){
      .btn-brand-primary {
        display: inline-block;
        text-align: center;
        margin-bottom: 25px;
      }
    }

    background: url('../Images/layout/check-expert-tool.svg') no-repeat right 25px $color-gray-light;
    background-size: 270px;
    min-height: 275px;
  }

  &.mt0 {
    margin-top: 0;
  }

  &.mt25 {
    margin-top: 25px;
  }

  &.mt50 {
    margin-top: 50px;
  }

  &.mb0 {
    margin-bottom: 0;
  }

  &.mb00 {
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }

  &.mb25 {
    margin-bottom: 25px;
  }

  &.mb50 {
    margin-bottom: 50px;
  }

  &.full {
      padding: 15px;

      .neos-nodetypes-image {
          img {
              @include media-breakpoint-down(sm) {
                min-height: 634px;
                width: 100%!important;
                object-fit: cover;
                object-position: left center;
              }
          }
      }

      .grid-content-col {
          &.col-12 {
              padding: 0;
          }
      }
  }

  &.less-padding-right {
      padding-right: 10px;
  }
}

.grid-content-col {
  @include media-breakpoint-down(sm){
    margin-top: 15px;
  }

  &.inner-well {
    > .neos-contentcollection {
      @extend .well;
    }
  }

  &.no-wrap {
    > .neos-contentcollection {
      @extend .row;
      flex-wrap: wrap;
      display: flex;
      // justify-content: space-between;

      &::after {
        content: none;
      }
    }
  }

  &.mb25 {
      margin-bottom: 25px
  }

  &.mb30 {
      margin-bottom: 30px
  }

  &.col-4 {
      @include media-breakpoint-down(md) {
          @include make-col(6);
      }

      @include media-breakpoint-down(sm) {
          @include make-col(12);
      }
  }
  
  &.col-3 {
    &.offset-1 {
      @include make-col-offset(0);
    }
  }
}
