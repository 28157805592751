.callout {
  @include media-breakpoint-down(sm) {

  }

  // @extend .container;
  padding: 0;
  margin: 0 auto !important;
  position: relative;

  .carousel-item {
    @include media-breakpoint-down(sm) {
        height: 104px;
    }

    height: 265px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $brand-secondary;
  }
  
  .carousel {
    &:has(.callout-video) {
      ~ .header-claim {
        display: none;
      }
    }
  }
}

.callout-caption {
  @extend .container;
  height: 100%;
}

.callout-caption-box {
  display: none;
  position: absolute;
  padding: 20px;
  background-color: $callout-caption-bgcolor;
  color: $brand-primary;

  @include media-breakpoint-up(sm) {
    display: block;
    left: 15%;
    top: 40%;
    width: 350px;
  }
}
