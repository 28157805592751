%form-title {

  h2, h3 {
    border-top: 2px solid #058036;
    background: url('../Images/layout/arrow-green.svg') no-repeat 0 5px $color-green-light;
    background-size: 16px 25px;
    color: #058036;
    padding: 10px 25px;
    margin-bottom: 0;
  }
}

%form-title-inverted {

  h2, h3 {
    border-top: 2px solid #058036;
    background: url('../Images/layout/arrow-right-white.svg') no-repeat 0 5px $color-green;
    background-size: 16px 25px;
    color: $white;
    padding: 10px 25px;
    margin-bottom: 0;
  }
}