.well {
  position: relative;
  box-shadow: none;

  .more {

    > a {
      @include media-breakpoint-up(md) {
        line-height: $line-height-base * 2;
      }

      &:after {
        content: "\e080"; /* glyphicon-chevron-right */
        font-family: "Glyphicons Halflings";
        font-size: $font-size-small;
        line-height: $font-size-small * 0.5;
        padding-left: 5px;

        @include media-breakpoint-down(sm) {
          right: 20px;
        }
      }
    }
  }
}
