.banner-info--wrapper {
    @include make-col(9);
    
    @include media-breakpoint-down(md) {
        @include make-col(10);
    }
    
    @include media-breakpoint-down(sm) {
        @include make-col(12);
    }
    
    .neos-nodetypes-headline {
        > span {
            margin-left: 0;
            color: #D8D8DA;
            margin-top: 0;
            margin-bottom: 0;
            font-family: $font-family-light;
            font-size: 17px;
            line-height: 1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            height: 20px;
        }
        
        h1 {
            margin-top: 5px;
        }
    }
    
    .neos-nodetypes-text {
        margin-bottom: 25px;
    }
    
    .btn-brand-primary {
        background-color: white;
        color: $color-green;
        display: inline-block;
        line-height: normal;
        
        &:hover {
            text-decoration: none;
            background-color: darken(white, 4%);
            color: $color-green;
          }
    }
}