.text-with-pictogram {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 0 0 0;

  h2, h3 {
    margin-top: 0;
  }

  &.divider {
    border-bottom: 1px solid $color-gray;
  }

  .text-with-pictogram-pictogram {
    img {
      @include media-breakpoint-down(sm){
        width: 30px;
      }
    }

    @include make-col(1);
  }

  .text-with-pictogram-text {
    @include make-col(10);
    padding-left: 10px;

    ul {
      margin-bottom: 0.5rem;
    }
  }

  & + .neos-nodetypes-image {
    @include make-col-offset(1);
    padding-left: 10px;

    & + .neos-nodetypes-image {
      @include make-col-offset(1);
      padding-left: 10px;

      & + .neos-nodetypes-image {
        @include make-col-offset(1);
        padding-left: 10px;
      }
    }

  }

}
