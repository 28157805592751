@include media-breakpoint-up(lg) {
  .show > .dropdown-menu {
    display: none;
  }
  .dropdown-toggle {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .dropdown-menu.second-level-sub-navigation {
    .dropdown-menu-mobile-item {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .dropdown {
    > a {
      display: none !important;
    }

    > a.dropdown-toggle {
      display: block !important;
    }

    &.has-no-subitems {
      > a {
        display: block !important;
      }
    }

    &.disabled::after {
      display: none !important;
    }

    .dropdown-toggle {
      &::after,
      &::after,
      &::after {
        transform: rotateX(0);
        color: $color-white;
      }
    }

    &.show {
      .dropdown-toggle {
        &::after,
        &::after,
        &::after {
          transform: rotateX(180deg);
          color: $color-white;
        }
      }
    }
  }

}

.dropdown-menu {
  min-width: 100%;
  padding: 5px 0;
  margin: 2px 0 0; // override default ul
  font-size: inherit;
  font-weight: 300;
  background-color: $color-gray-light;
  border-radius: 0;
  @include box-shadow(0 6px 12px rgba(0,0,0,.0));
  background-clip: padding-box;

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 20px 40px;
    clear: both;
    font-weight: $font-weight-light;
    line-height: $line-height-base;
    color: $brand-secondary;
    white-space: pre-wrap;
  }
}

.dropdown-menu > li {
  position: relative;
  border-bottom: 1px solid #879dab;
}
// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $color-gray-light;
  }
}

// show state for the dropdown
.show {
  // Show the menu
  > .dropdown-menu {
    position: static !important;
    float: none !important;
    transform: translate3d(0, 10px, 0px) !important;
    width: auto;
    margin-top: 0;
  }
}
