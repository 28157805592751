.office-legend {
    display: flex;
    margin-top: 50px;
    @include make-col(12);
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }

    .office-legend-item {
        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        display: flex;
        margin-right: 20px;

        @include media-breakpoint-down(md) {
            margin-right: 14px;
        }

        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }

        align-items: center;

        p {
            margin-bottom: 0;
        }

        img {
            margin-left: 7px;
            height: 24px;
            width: 24px;
        }
    }
}

.office-item {
    // @include make-col(11);
    padding: 25px 15px;
    border-bottom: 1px solid #666;

    h3 {
        margin-top: 4px;
        font-family: $font-family-regular;
        font-size: $font-size-medium;
        color: $brand-primary;
    }

    h4 {
        font-size: $font-size-medium;
    }

    p {
        font-size: $font-size-medium;
        line-height: 1.4rem;

        a {
            text-decoration: underline;
        }
    }

    .office-item-company,
    .office-item-representative,
    .office-item-adress {
        @include media-breakpoint-down(sm) {
            @include make-col(8);
            margin-bottom: 5px;
        }
        @include make-col(3);
    }

    .office-item-representative,
    .office-item-distributor,
    .office-item-service {
        @include media-breakpoint-down(sm) {
            @include make-col(2);
        }
        @include make-col(3);
        display: flex;
        flex-wrap: nowrap;

        span {
            flex: 0 0 70%;
            width: 25px;
            height: 25px;
        }

        .chemie {
            background: url("../Images/layout/chemie.svg") no-repeat;
            background-size: 25px 25px;

            @include media-breakpoint-down(md) {
                background-size: 20px 20px;
            }
        }

        .refrigeration {
            background: url("../Images/layout/kaelte.svg") no-repeat;
            background-size: 25px 25px;

            @include media-breakpoint-down(md) {
                background-size: 20px 20px;
            }
        }

        .oilgas {
            background: url("../Images/layout/oel-gas.svg") no-repeat;
            background-size: 25px 25px;

            @include media-breakpoint-down(md) {
                background-size: 20px 20px;
            }
        }

        .energy {
            background: url("../Images/layout/energie.svg") no-repeat;
            background-size: 25px 25px;

            @include media-breakpoint-down(md) {
                background-size: 20px 20px;
            }
        }

        .service {
            background: url("../Images/layout/service.svg") no-repeat;
            background-size: 25px 25px;

            @include media-breakpoint-down(md) {
                background-size: 20px 20px;
            }
        }
    }

    .office-item-adress {
        .office-item-area {
            p {
                margin-bottom: 0;
                line-height: 1.3rem;
            }
            .area-us-states {
                font-size: 16px;
                display: inline-flex;

                .office-state {
                    list-style: none;
                    display: contents;
                    &:not(:nth-child(2)) {
                        &::before {
                            content: ",\00a0 ";
                        }
                    }
                }
            }
        }
    }

    .office-item-adress,
    .office-item-contact {
        padding-left: 15px;
        
        .second-websitelink {
            display: block;
        }
    }

    .office-item-company {
        @include media-breakpoint-down(md) {
            padding-left: 15px;
            margin-bottom: 15px;
        }

        h3 {
            @include media-breakpoint-down(md) {
                font-size: 1.2rem;
            }
        }
    }
}
