.languages {
    display: inline-block;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: -20px;
        right: 0px;
    }

    @include media-breakpoint-down(sm) {
        top: -40px;
    }

    .languages-list {
        list-style: none;
        display: flex;
        margin-bottom: 0;
        padding-left: 26px;

        li {
            line-height: normal;
            font-size: 14px;
            padding-right: 4px;

            &:last-child {
                padding-right: 0;
                position: relative;
                padding-left: 4px;

                &::before {
                    content: '|';
                    color: inherit;
                    position: absolute;
                    left: -2px;
                }
            }

            &.current  {
                a {
                    font-family: $font-family-medium;
                }
            }
        }

        a {
            color: #707070;
        }


    }
}
