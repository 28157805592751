.neos-nodetypes-text {
  &.color-primary {
    color: $brand-primary;
  }

  &.default {
    background-color: transparent;
  }

  &.green-light,
  &.green,
  &.gray-light {
    padding: 0 25px 25px;
    color: $color-gray;

    a {
      text-decoration: underline;

      &:hover {
        font-family: $font-family-regular;
      }
    }
  }

  &.green-light {
    background-color: $color-green-light;
    border-bottom: 2px solid $brand-primary;
  }

  &.green {
    padding: 15px 25px 25px;
    background-color: $brand-primary;
    color: $color-white;

    a {
      color: $color-white;
    }
  }

  &.gray-light {
    padding: 15px 25px 25px;
    background-color: $color-gray-light;
  }

  & + .neos-nodetypes-image,
  & + .neos-nodetypes-text-with-image {
    margin-top: 25px;
  }

  &.button-green {

    a {
      @extend .btn-brand-primary;
      @extend .inline-block;
      text-decoration: none;
      margin-top: 15px;
    }
  }

  &.button-gray {

    a {
      @extend .btn-brand-secondary;
      @extend .inline-block;
      text-decoration: none;
      margin-top: 15px;
    }
  }
}