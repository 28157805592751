.content-circle {
    background-image: url("../Images/layout/startseite_kreis_hell.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(sm) {
      background-image: none;
    }

    &:hover {
        background-image: url("../Images/layout/startseite_kreis_dunkel.svg");
        cursor: pointer;

        @include media-breakpoint-down(sm) {
          background-image: none;
        }
    }

    &.pumps {
        position: absolute;
        left: 28%;
        top: 17%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          left: 28%;
          top:17%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 80px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }

    &.pump-design {
        position: absolute;
        left: 3%;
        top: 17%;
        width: 200px;
        height: 200px;

        @media (min-width: 2560px) {
          right:77%;
        }

        @media (max-width: 1440px) {
          right: auto;
          left: 3%;
        }

        @include media-breakpoint-down(lg) {
          left: 3%;
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          right: auto;
          left: 3%;
          top: 17%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 145px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }

    &.pumped-media {
        position: absolute;
        left: 28%;
        top: 55%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 210px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }

    &.hermetic {
        position: absolute;
        right: 28%;
        top: 55%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 275px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }

    &.applications {
        position: absolute;
        top: 17%;
        right: 28%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          top: 17%;
          right: 28%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 340px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }

    &.contact {
        position: absolute;
        right: 3%;
        top: 55%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 470px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }

    &.training {
        position: absolute;
        right: 3%;
        top: 17%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          right: 3%;
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          right: 3%;
          top: 17%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 405px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
    }
    
    &.pump-technology {
      position: absolute;
      left: 3%;
      top: 55%;
      width: 200px;
      height: 200px;
      
      @include media-breakpoint-down(lg) {
        width: 180px;
        height: 180px;
      }

      @include media-breakpoint-down(md) {
        width: 150px;
        height: 150px;
      }

      @include media-breakpoint-down(sm) {
        position: absolute;
        width: auto;
        height: auto;
        top: 535px;
        left:0;
        right: 0;
        display: block;
        margin: 0 17px;
      }
    }

   .pumps {
        background-image: url("../Images/layout/startseite_pumpentypen.svg");
        background-repeat: no-repeat;
        background-size: 56%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;

        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
              top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: auto;
            }
        }


        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .pump-design {
        background-image: url("../Images/layout/startseite_pumpenauslegung.svg");
        background-repeat: no-repeat;
        background-size: 52%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;

        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
              top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: auto;
            }
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 92%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .pumped-media {
        background-image: url("../Images/layout/startseite_foerdermedien.svg");
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;


        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
                top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: auto;
            }
        }


        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .hermetic {
        background-image: url("../Images/layout/startseite_unternehmen_hermetic.svg");
        background-repeat: no-repeat;
        background-size: 30%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;


        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }

        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
                top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: 0;
            }
        }
    }

    .applications {
        background-image: url("../Images/layout/startseite_applikationen.svg");
        background-repeat: no-repeat;
        background-size: 26%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;

        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
              top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: 0;
            }
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .contact {
        background-image: url("../Images/layout/startseite_kontakt.svg");
        background-repeat: no-repeat;
        background-size: 25%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;

        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
                top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: 0;
            }
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .training {
        background-image: url("../Images/layout/startseite_tipps_training.svg");
        background-repeat: no-repeat;
        background-size: 31%;
        background-position-x: center;
        background-position-y: center;
        position: relative;
        top: 25%;
        height: 70px;

        > p {
            top: 80px;

            @include media-breakpoint-down(lg) {
                top: 68px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: 0;
            }
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 28%;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }
    
    .pump-technology {
      background-image: url("../Images/layout/startseite_pumpentechnologien.svg");
      background-repeat: no-repeat;
      background-size:  49% 78%;
      background-position-x: center;
      background-position-y: center;
      position: relative;
      top: 25%;
      height: 70px;
      
      @include media-breakpoint-down(lg) {
        background-size: 44% 78%;
      }

      @include media-breakpoint-down(md) {
        background-size: 100% 100%;
        top: 28%;
        width: auto;
        height: 33px;
      }

      @include media-breakpoint-down(sm) {
        background-image: none;
        background-color: rgba($color: #000000, $alpha: 0.4);
        top: 0;
        height: auto;
        padding: 13px 0;
      }

      > p {
        top: 80px;

        @include media-breakpoint-down(lg) {
            top: 68px;
            word-break: break-word;
            hyphens: auto;
        }

        @include media-breakpoint-down(md) {
          top: 45px;
          margin: 0 22px;
        }

        @include media-breakpoint-down(sm) {
          top: 0;
        }
      }
    }

    p {
        position: relative;
        top: 126px;
        text-align: center;
        $font-family-medium: 'Vialog LT W01 Medium', Tahoma, Arial,sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px;
        line-height: 20px;

        @include media-breakpoint-down(lg) {
          top: 68px;
          font-size: 14px;
        }

        @include media-breakpoint-down(md) {
          top: 45px;
        }

        @include media-breakpoint-down(sm) {
          top: 0;
          margin-bottom: 0;
          height: auto;
          font-size: 20px;
          font-family: $font-family-medium;
        }
    }
}

.content-circle {
    &.active {

    }
}

.content-circle-popup {
    display: none;
        &.active {
           display: block;

           @include media-breakpoint-down(md) {
            display: none;
          }

            &#pumpentypen-content {

            p {
                text-align: left;
                text-transform: none;
                position: relative;
                right: -129px;
                top: -137px;
                width: 289px;
                padding: 20px 20px 50px 78px;
                line-height: 26px;
                z-index: 1;

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 289px;
                  height: 141px;
                  z-index: -1;
                  background-image: url("../Images/layout/circle-hover-bg.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                &::after {
                  content: '';
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  z-index: -1;
                  margin-left: 10px;
                  margin-top: 5px;
                  background-image: url("../Images/layout/arrow-circle-content.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }
            }
           }

           &#pump-design-content {

            p {
                text-align: left;
                text-transform: none;
                position: relative;
                right: -130px;
                top: 38px;
                width: 289px;
                padding: 33px 20px 20px 84px;
                line-height: 26px;
                z-index: 0;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 289px;
                    height: 141px;
                    z-index: -1;
                    transform:scaleY(-1);
                    background-image: url("../Images/layout/circle-hover-bg.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                  }

                  &::after {
                    content: '';
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    z-index: -1;
                    margin-left: 10px;
                    margin-top: 5px;
                    background-image: url("../Images/layout/arrow-circle-content.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                  }
              }
        }

        &#pumping-media-content {

          p {
              text-align: left;
              text-transform: none;
              position: relative;
              right: -132px;
              top: 38px;
              width: 289px;
              padding: 20px 20px 20px 95px;
              line-height: 26px;
              z-index: 1;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 289px;
                height: 141px;
                z-index: -1;
                transform:scaleY(-1);
                background-image: url("../Images/layout/circle-hover-bg.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }

              &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                z-index: -1;
                margin-left: 10px;
                margin-top: 5px;
                background-image: url("../Images/layout/arrow-circle-content.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }
          }
      }

        &#pump-technology-content {

          p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: -130px;
            top: 38px;
            width: 289px;
            padding: 20px 20px 20px 95px;
            line-height: 26px;
            z-index: 1;
            word-break: break-word;
            hyphens: auto;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 289px;
              height: 141px;
              z-index: -1;
              transform:scaleY(-1);
              background-image: url("../Images/layout/circle-hover-bg.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }

            &::after {
              content: '';
              position: absolute;
              width: 17px;
              height: 17px;
              z-index: -1;
              margin-left: 10px;
              margin-top: 5px;
              background-image: url("../Images/layout/arrow-circle-content.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
        }
      }

      &#hermetic-content {

        p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: -130px;
            top: 38px;
            width: 289px;
            padding: 20px 20px 20px 95px;
            line-height: 26px;
            z-index: 1;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 289px;
              height: 141px;
              z-index: -1;
              transform:scaleY(-1);
              background-image: url("../Images/layout/circle-hover-bg.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }

            &::after {
              content: '';
              position: absolute;
              width: 17px;
              height: 17px;
              z-index: -1;
              margin-left: 10px;
              margin-top: 5px;
              background-image: url("../Images/layout/arrow-circle-content.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
        }
      }

      &#applications-content {

          p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: -130px;
            top: 38px;
            width: 289px;
            padding: 20px 20px 20px 95px;
            line-height: 26px;
            z-index: 1;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 289px;
                height: 141px;
                z-index: -1;
                transform:scaleY(-1);
                background-image: url("../Images/layout/circle-hover-bg.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }

              &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                z-index: -1;
                margin-left: 10px;
                margin-top: 5px;
                background-image: url("../Images/layout/arrow-circle-content.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }
          }
      }

      &#contact-content {

        p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: 219px;
            top: 38px;
            width: 289px;
            padding: 20px 78px 50px 20px;
            line-height: 26px;
            z-index: 0;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 289px;
              height: 141px;
              z-index: -1;
              transform:rotate(180deg);
              background-image: url("../Images/layout/circle-hover-bg.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }

            &::after {
              content: '';
              position: absolute;
              width: 17px;
              height: 17px;
              z-index: -1;
              margin-left: 10px;
              margin-top: 5px;
              background-image: url("../Images/layout/arrow-circle-content.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
        }
      }

      &#training-content {

        p {
          text-align: left;
          text-transform: none;
          position: relative;
          right: 220px;
          top: 38px;
          width: 289px;
          padding: 20px 78px 50px 20px;
          line-height: 26px;
          z-index: 0;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 289px;
            height: 141px;
            z-index: -1;
            transform:rotate(180deg);
            background-image: url("../Images/layout/circle-hover-bg.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }

          &::after {
            content: '';
            position: absolute;
            width: 17px;
            height: 17px;
            z-index: -1;
            margin-left: 10px;
            margin-top: 5px;
            background-image: url("../Images/layout/arrow-circle-content.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }

      .content-circle-popup {
          @include media-breakpoint-down(md) {
              visibility: hidden;
          }
      }
    }
}

.circle-content-backend {
    .pumps, .pump-design, .pumped-media, .hermetic, .applications, .contact , .training, .pump-technology {
        position: relative;
        right: 0;
        left: 0;
        top: auto;
        p {
            color: black;
            top: 0;
            text-align: left;
            width: 300px;
        }
    }
}
