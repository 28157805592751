.landingpage {

  .grid-content {

    &:first-of-type {
      padding: 0 15px 15px 15px;
    }
  }

  h2 {
    font-size: $font-size-base;
    font-family: $font-family-medium;
  }

  h3 {
    color: $brand-primary;
  }

  .neos-nodetypes-text-with-image {
    @include media-breakpoint-down(sm){
      display: block;
    }
    @include make-col(12);
    @include make-col-offset(1);
    margin-top: 1rem;
    display: flex;
    flex-wrap: nowrap;

    &.simple-download {
      @include make-col-offset(0);
    }

    .neos-nodetypes-text-with-image-image {
      @include make-col(6);
    }

    .neos-nodetypes-text-with-image-text {
      @include media-breakpoint-down(sm){
        @include make-col(12);
      }
      @include make-col(6);

      h3 {
        margin-top: 5px;
      }
    }
  }
}
