@font-face{
  font-family:"Vialog LT W01 Light";
  src:url("../Fonts/23e496b3-fc90-4c4b-bd96-6e23058a7b99.woff2") format("woff2"),url("../Fonts/54346eed-9cc2-40c1-b1f8-ed0f27ca89c7.woff") format("woff");
}

@font-face{
  font-family:"Vialog LT W01 Regular";
  src:url("../Fonts/3345c2a3-53d4-44d2-9d9e-20beb3b4dbbc.woff2") format("woff2"),url("../Fonts/14fac140-c288-4bc4-821c-123094189100.woff") format("woff");
}

@font-face{
  font-family:"Vialog LT W01 Medium";
  src:url("../Fonts/adcefd93-bb7a-42b0-91d5-ca89f23106f6.woff2") format("woff2"),url("../Fonts/036f9352-9253-42d9-9b42-365278339eab.woff") format("woff");
}

@font-face{
  font-family:"VialogLTW01-MediumItali";
  src:url("../Fonts/2926eca6-22dc-443f-a3c5-7792c61763fd.woff2") format("woff2"),url("../Fonts/fdd6cfa4-4ebb-4cd4-ac66-d3a8f20e0797.woff") format("woff");
}

@font-face{
  font-family:"Vialog LT W01 Bold";
  src:url("../Fonts/0bad5ffb-8dd3-4093-9912-d7fdf627e49f.woff2") format("woff2"),url("../Fonts/09b4b5c8-02da-41fb-9b62-b2d18a91b0d9.woff") format("woff");
}