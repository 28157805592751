.career-teaser--wrapper {
    display: flex;
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
        display: block;
    }

    .career-teaser-image {
        @include make-col(4);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        figure {
            margin-bottom: 0;
            height: 100%;

            img {
                width: 100%;
                object-fit: cover;
                height: inherit;
                min-height: 180px;
            }
        }
    }

    .career-teaser-content {
        @include make-col(8);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        padding: 21px 25px  25px 21px;
        background-color: #E8E7E7;

        h3 {
            margin-top: 0;
            font-size: 24px;
            font-family: $font-family-light;
            color: $color-green;
        }

        p {
            margin-bottom: 0;
            line-height: 29px;
        }
    }
}
