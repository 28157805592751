.form-title {
  @extend %form-title;
}

.form-control {
  height: auto !important;
}

.form-subtitle {
    h4 {
        background-color: #ecf5ec;
        padding: 0 25px 5px ;
        margin-bottom: 0;
        margin-top: 0;
        font-family: $font-family-light;
    }
}

form#form {
  padding-top: 20px;

  h2 {
    width: 100%;
    font-size: $font-size-medium;
    font-family: $font-family-medium;
    color: $color-gray;
    margin-top: 0.5rem;
  }

  .help-inline {
    font-size: $font-size-small;
    color: red;
  }

  .form-columns {

    .col-sm-5 {

      .form-group.contact-column {
        @include make-col(12);
      }
    }
  }

  .form-group {

    &.contact-column {
      @include make-col(12);
    }

    &.contact-stromanschluss,
    &.contact-entleerung {

      .inputs-list {
        display: flex;
        list-style-type: none;
        padding-left: 0;

        li {
          margin-right: 20px;

          label {
            display: flex;
            align-items: center;

            input {
              width: 25px;
            }

            span {
              font-size: $font-size-small * 1.2;
            }
          }
        }
      }
    }
  }
}

#form-refrigeration  {
  background-color: $color-green-light;
  border-bottom: 2px solid $brand-primary;
  padding: 10px 25px;

  fieldset {
    @include make-col(12);

    fieldset {
      @include make-col(12);
    }

  }

  .form-group {
    margin-bottom: 0.5rem;
    flex-wrap: nowrap;

    input, textarea {
      background-color: transparent;
      border: 1px solid $color-gray-medium-light;
    }
  }

  .contact-dsgvo {
    p {
      font-size: $font-size-small;

      a {
        color: $color-gray;
        text-decoration: underline;
      }
    }
  }

  .contact-submit {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(8);
    }

    .form-control.btn-brand-secondary {
      font-family: $font-family-light;
      letter-spacing: 0.5px;
    }
  }

  .required {
    font-size: $font-size-small;
  }

  .row.form-columns {
    @include make-col(12);

    margin-left: 0;
    margin-right: 0;
    background-color: #D1E0CF;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
        @include make-col(8);
    }

    .col-sm-12 {
        h2 {
            font-size: inherit;
            font-family: $font-family-light;
        }
    }

    .col-sm-6 {
        label.add-on {
            display: flex;
            align-items: center;

            input {
                width: auto;
                margin-right: 10px;
                position: relative;
                top: -4px;
            }
        }
    }
}
}


