.simple-download.neos-nodetypes-text-with-image {
  @include make-col(4);
  margin-top: 0;
  margin-right: 25px;
  float: left;

  .download-content {
    @include make-col(12);

    a {
      color: $color-gray-dark;
      text-decoration: underline;
      font-size: $font-size-medium;

      &:hover {
        color: $brand-primary;
      }
    }

    .download-thumb {

      .portrait {
        width: 105px;

        img {
          width: 100%;
        }
      }

      .landscape {
        width: 150px;
      }

      img {
        border: 1px solid $color-gray-medium-light;
      }
    }
  }

}