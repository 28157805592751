
.banner-section {
    display: flex;


    .pump-icon {
        display: flex;
        justify-content: flex-end;
        @include make-col(4);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .banner-content {
        @include make-col(8);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }


        h2, h3, h4 {
            margin-bottom: 0;
            margin-top: 0;
            line-height: 33px;
            font-family: $font-family-light;

            a {
                color: inherit;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    border-bottom: 1px solid white;
                }
            }

            @include media-breakpoint-down(md) {
              margin-top: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 10px;
    }
}
