body {
  > .content {
    display: flex;
    @extend .container;
    padding: 0;
    margin: 0 auto;
    min-height: calc(100vh);

    img {
      @extend .img-fluid;
    }

    ul {
      padding-left: 20px;
    }

    table {
      @extend .table;
    }
  }
}

.content {
  @include media-breakpoint-down(sm){
    display: block;
  }

  @include make-col(12);
  display: flex;
  flex-wrap: wrap;

  .main {
    p {
      a {
        @include media-breakpoint-down(sm){
          line-break: anywhere;
        }

        color: black;
        text-decoration: underline;
      }
    }
    ul {
      li {
        a {
          color: black;
          text-decoration: underline;
        }
      }
    }
    div.download-content-assets {
      ul {
        li {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.wrapper {
  position: relative;
  padding: 0;
  max-width: 1170px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px #ccc;
}
 

.no-subnav {
    .main {
      &#home, &#Kältetechnik, &#Refrigeration {
          @include make-col(12);
          padding-left: 0;
          padding-right: 0;
        }
    }
}

.main {
  @include media-breakpoint-down(sm){
    @include make-col(12);
  }
  @include make-col(9);
  @extend .container-fluid;
}

.no-subnav {
  .main {
    @include media-breakpoint-down(sm){
      padding-left: 0;
      padding-right: 0;
    }
    @include make-col(12);
    @extend .container-fluid;
  }
}

.landingpage {
  @include media-breakpoint-down(sm){
    width: 375px;
  }

  .main {
    @include make-col(7);
    @include media-breakpoint-down(sm){
      @include make-col(12);
    }
  }

  .sidebar {
    @include make-col(5);
    @include media-breakpoint-down(sm){
      @include make-col(12);
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.webinar {

    .header-nav {
      margin-bottom: 25px;
    }

    .main {
      @include make-col(8);
      @include media-breakpoint-down(sm){
        @include make-col(12);
      }
    }

    .sidebar {
      @include make-col(4);
      @include make-col-offset(0);
      @include media-breakpoint-down(sm){
        @include make-col(12);
        padding-left: 15px;
        padding-right: 15px;
      }

      .grid-content-col {
        padding: 10px 10px 30px 10px;
        box-shadow: -2px 3px 12px $color-gray-light;

        .neos-nodetypes-image {

          img {
            border-top: 0;
          }
        }

        .neos-nodetypes-text {
          padding-right: 15px;

          &.green {
            padding: 15px 25px 10px;
          }
        }

        .btn-brand-primary {
          width: 100%;
          margin-left: 0;
          text-align: center;
          @include media-breakpoint-up(lg) {
            width: 50%;
            margin-left: 25%;
          }
        }
      }

    }
  }
}

.neos-contentcollection {
  @include clearfix;
}

h1 {
  font-family: $font-family-regular;
  font-size: $font-size-h1;
  color: $color-gray-dark;
  margin-bottom: 1rem;

  i {
    font-family: $font-family-light;
    color: $brand-primary;
    font-style: normal;
  }
}

h2 {
  font-family: $font-family-regular;
  font-size: $font-size-h2;
  color: $color-black;
  margin: 1.5rem auto 0.5rem auto;
  line-height: 1.8rem;

  i {
    font-family: $font-family-light;
    color: $brand-primary;
    font-style: normal;
  }
}

h3 {
  font-size: $font-size-h3;
  font-family: $font-family-medium;
  margin: 1rem auto 0.5rem auto;
}

h4 {
  font-size: $font-size-h4;
  font-family: $font-family-medium;
  margin: 1rem auto 0.5rem auto;
}

ul {
  list-style-type: square;
  padding-left: 20px;
  margin-bottom: 1.5rem;
}

ol {
  li {
    margin-bottom: 20px;
  }
}

p {
  margin-bottom: 0.5rem;
}

strong, b {
  font-family: $font-family-medium;
  font-weight: normal;
}

.table {
  table {
    tbody {
      tr {
        td {
          padding: 0 0.75rem 0 0;
          border: none;
        }
      }
    }
  }
}
