.neos-nodetypes-industry {
  @include media-breakpoint-down(sm){
    @include make-col(12);

    figure {
      background-size: contain;
    }
  }

  @include media-breakpoint-up(sm) {
    height: 190px;

    figure {
      height: 190px;

      figcaption {

        p {
          font-size: $font-size-base;
        }
      }
    }
  }

  flex-wrap: nowrap;
  height: 312px;
  width: 20%;
  padding: 0 15px;
  margin-bottom: 20px;

  figure {
    height: 100%;
    background-repeat: no-repeat;
    position: relative;

    figcaption {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 45px;
      background-color: rgba(255,255,255,0.7);
      text-align: center;
      padding-top: 7px;
      font-family: $font-family-medium;

      p, a {
        font-style: normal;
        color: $color-black;
        font-size: $font-size-base;
      }
    }
  }
}
