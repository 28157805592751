.btn-brand-primary {
  @include media-breakpoint-down(sm) {
    padding: 12px 10px;
  }
  border-radius: 0;
  background-color: $brand-primary;
  color: $color-white;
  padding: 12px 30px;
  border-radius: 5px;
  font-family: $font-family-medium;
  letter-spacing: 1px;
  font-size: $font-size-medium;

  &:hover {
    text-decoration: none;
    background-color: lighten($brand-primary, 4%);
    color: $white;
  }
}

.btn-brand-secondary {
  @extend .btn-brand-primary;
  background-color: $color-gray;
  color: $white;

  &:hover {
    text-decoration: none;
    background-color: $brand-primary;
    color: $white;
  }
}

.btn-brand-primary, .btn-brand-secondary {

  &.inline-block {
    display: inline-block;
    height: 44px;
    margin-right: 25px;
    padding: 9px 30px;
    
    @include media-breakpoint-down(sm){
      display: block;
      margin-bottom: 20px;
      margin-right: 0;
      text-align: center;
      height: auto;
    }

    &.mt25 {
      margin-top: 25px;
    }

    &.mt50 {
      margin-top: 50px;
    }

    &.mb25 {
      margin-bottom: 25px;
    }

    &.mb50 {
      margin-bottom: 50px;
    }
  }
}

.btn-primary {
  @extend .btn-brand-primary;
}