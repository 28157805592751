@use "sass:math";

.subnav {
  @include media-breakpoint-down(sm){
    display: none;
  }
  @include make-col(3);
  padding-left: 25px;
  margin-top: 35px;

  .subnav-list {
    font-family: $font-family-regular;
    max-width: 250px;
    list-style-type: none;
    padding: 0 math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2) 0;
    margin: 5px 0;
    background-color: $color-white;
    text-align: left;

    li {
      @include make-col(12);
      padding-right: 5px;
      text-align: left;
      border: 0 solid $color-gray;
      border-bottom-width: 1px;
      line-height: 1.2;
      min-height: 45px;
      display: flex;
      flex-wrap: wrap;

      &:first-of-type {
        border: 0 solid $color-gray;
        border-top-width: 1px;
        border-bottom-width: 1px;
      }

      a {
        font-size: $font-size-medium;
        color: $color-gray-dark;
        padding: 3px 0;
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }

      &.current {

        a {
          color: $brand-primary;
        }
      }

      ul.second-level-sub-navigation {
        flex-wrap: wrap;
        display: flex;

        li {
          min-height: 25px;
          border: none;
          padding: 1px 5px;

          &.current {
            background-color: $color-green-lighter;
          }

          a {
            font-family: $font-family-light;
            line-height: 1.5rem;
            color: $color-gray-dark;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
