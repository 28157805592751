.download {
  background-color: $color-gray-light;
  margin: 25px auto 10px auto;
  padding: 20px;

  h2 {
    font-family: $font-family-regular;
    font-size: $font-size-large;
    padding-bottom: 5px;
    margin: 0 auto 0.7rem auto;
    border-bottom: dotted 2px $color-gray-dark;
  }

  p {
    margin-bottom: 10px;
  }


  .download-content {
    @include media-breakpoint-down(sm){
      @include make-col(12);
    }
    @include make-col(8);

    .download-content-text {
      border-bottom: dotted 2px $color-gray-dark;
    }

    .download-content-assets {
      ul {
        padding-top: 5px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;

        li {
          list-style-type: none;

          &::after {
            content: '|';
            padding: 0 5px;
          }

          &:last-child {
            &::after {
              content: '';
            }
          }

          a {
            color: $color-gray;

            &:hover {
              color: $brand-primary;
            }
          }
        }
      }
    }
  }

  .download-thumb {
    @include media-breakpoint-down(sm){
      display: contents;
      // @include make-col(4);
    }
    display: flex;
    justify-content: flex-end;
    @include make-col(3);
    @include make-col-offset(1);

    img {
      border: 1px solid $color-gray-medium-light;
    }
  }

}
