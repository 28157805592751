#google-maps {
    min-height: 480px;
    width: 100%;
    position: relative;

    .bg-image {
        position: absolute;
        background-image: url('../Images/layout/map.svg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        filter: blur(3px);
        -webkit-filter: blur(3px);
        opacity: 0.6;
    }

    .google-maps-wrapper {
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
        width: 75%;
        padding: 20px;
        background-color: rgba($color: #fff, $alpha: 0.8);
        border-radius: 5px;
        color: $color-black;
        text-align: center;

        @include media-breakpoint-down(md){
            width: 95%;
        }

        @media (max-width: 320px) {
            width: 100%;
        }
        
        .neos-nodetypes-headline {
            h3 {
                margin-top: 0;
            }
        }
        
        .neos-nodetypes-text {
            margin-bottom: 15px;
        }

        .google-maps-opt-in-button {
            display: flex;
            justify-content: center;
            gap: 12px;

            @include media-breakpoint-down(lg){
                flex-wrap: wrap;

                .google-maps-opt-in-button-disagree > a {
                    margin-bottom: 0;
                }
            }
        }
        
        .google-maps-opt-in-button-agree, .google-maps-opt-in-button-disagree {
            a {
                @extend .btn-brand-secondary;
                display: inline-block;
                line-height: normal;
                cursor: pointer;
                margin-top: 0;
            }
        }

    }

    #map {
        display: none;
    }
}