@use "sass:math";

$header-logo-height-md: 60px;
$header-logo-height-xs: math.div($header-logo-height-md, 2);

.header--wrapper {

    &.fixed {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;

        .header {
            height: 75px;
            transition: ease-in 0.25s all;

            @include media-breakpoint-down(sm) {
                height: 55px;
            }
        }

        .header-logo {

            @include media-breakpoint-down(md) {
                top: 18px;
            }

            @include media-breakpoint-down(sm) {
                top: 12px;
            }

            img {
                height: 40px;
                transition: ease-in 0.25s all;

                @include media-breakpoint-down(sm) {
                    height: 25px;
                }
            }
        }
    }
}

.header {
  @include media-breakpoint-down(md){
    height: 85px;
  }

  @include media-breakpoint-down(sm){
    height: 55px;
  }
  // @extend .container;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100px;
  transition: ease-in 0.25s all;

  .row {
      margin-left: 0;
      margin-right: 0;
  }


  .meta-nav {
    display: flex;
    width: 76%;
    max-width: 100%;
    margin-right: 30px;
    align-items: flex-end;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
        width: 72%;
    }

    @include media-breakpoint-down(md) {
        max-width: 62%;
    }

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 64px;
        right: 15px;
        max-width: 55%;
        margin-right: 0;
        flex-wrap: wrap-reverse;
    }


    > ul {
        list-style: none;
        display: flex;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }

        li {
            padding-right: 26px;
            font-size: 14px;
            line-height: normal;

            @include media-breakpoint-down(sm) {
                padding-left: 15px;
                padding-right: 0;
            }

            &:last-child {
                padding-right: 0;
            }
            a {
                color: #707070;
            }
        }
    }
 }
}

.header-claim {
  position: absolute;
  width: auto;
  bottom: 49px;
  right: 0;
  z-index: 9;

  @include media-breakpoint-up(lg){
   img {
       &:nth-child(2) {
           display: none;
       }
   }

    height: 48px;
    width: auto;
  }

  @include media-breakpoint-down(md){
    bottom: auto;
    top: -36px;
    right: 14px;

    img {
        &:first-child {
            display: none;
        }

        height: 19px;
    }
  }

  @include media-breakpoint-down(sm){
    bottom: 7px;
  }
}

.header-logo {

  @include media-breakpoint-down(md) {
    margin-left: 0;
    position: absolute !important;
    left: 15px;
  }

  @include media-breakpoint-up(lg) {
    // @include make-col(4);
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    margin-left: 30px;
    width: 18%;
  }



  img {
    height: $header-logo-height-xs;
    transition: ease-in 0.25s all;

    @include media-breakpoint-up(md) {
      height: $header-logo-height-md;
    }

    @include media-breakpoint-down(sm) {
      height: 34px;
    }
  }
}
