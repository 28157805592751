.teaser-news {
  @include media-breakpoint-down(sm){
    display: block;
  }

  display: block;
  border-top: 2px solid $color-white;
  padding: 25px 0;
  
  &:has(.teaser-button) {
    display: flex;
    
    .teaser-button {
      align-items: flex-start;
      
      .btn-brand-primary {
        height: auto;
      }
    }
  }

  &:last-of-type {
    border-bottom: 2px solid $color-white;
  }

  &.image {
    border-top: none;
    border-bottom: 1px solid $color-gray-medium-light;
    padding: 0 0 20px 0;
    margin-bottom: 45px;

    .teaser-content {
      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }
      @include make-col(6);
    }

    .teaser-button {
      display: flex;
      justify-content: flex-end;
      @include make-col(6);

      .btn-brand-primary.inline-block,
      .btn-brand-secondary.inline-block {
        margin-right: 0;
      }
    }
  }
  
  &.amongAlignment {
    display: block;
    
    .teaser-content {
      @include make-col(12);
    }
    
    .teaser-button {
      display: inline-block;
      @include make-col(12);

      .btn-brand-primary {
        height: auto;
      }
    }
  }
  
  &.flexAlignment7525 {
    .teaser-content {
      flex: 0 0 68%;
      max-width: 68%;
    }
    .teaser-button {
      flex: 0 0 32%;
      max-width: 32%;
    }
  }

  .teaser-content {
    @include media-breakpoint-down(sm){
      @include make-col(12);
      display: block;
    }
    @include make-col(12);

    h3 {
      font-size: $font-size-large * 1.1;
      font-family: $font-family-medium;

      &:first-child {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-bottom: 10px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  .teaser-image {
    margin-top: 20px;
  }

  .teaser-button {
    @include media-breakpoint-down(sm){
      @include make-col(12);
      display: block;
    }
    .btn-brand-primary {
      display: inline-block;
      line-height: normal
    }

    // @include make-col(3);
    padding-top: 8px;
  }
}
