.sidebar {

  .neos-nodetypes-headline {
    h3 {
      font-family: $font-family-regular;
    }
  }

  .col-12 {
    padding: 0;

    img {
      width: 100%;

      &:first-of-type {
        border-top: 2px solid $brand-primary;
      }
    }

    .sidebar-title-no-image {
      @extend %form-title;
    }

    .sidebar-title-inverted-no-image {
      @extend %form-title-inverted;
    }

    .sidebar-title.neos-nodetypes-headline {
      margin-top: -57px;
      background: url('../Images/layout/arrow-right-white.svg') no-repeat -1px 5px rgba(0,128,53,0.5);
      background-size: 16px 25px;
      position: relative;

      h3 {
        color: #fff;
        padding: 10px 25px;
        font-family: $font-family-light;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        margin-bottom: 0;
      }
    }

    form {
      background-color: $color-green-light;
      border-bottom: 2px solid $brand-primary;
      padding: 10px 25px;

      .form-group {
        @include make-col(10);
        margin-bottom: 0.5rem;

        input, textarea {
          background-color: transparent;
          border: 1px solid $color-gray-medium-light;
        }
      }

      .contact-dsgvo {
        p {
          font-size: $font-size-small;

          a {
            color: $color-gray;
            text-decoration: underline;
          }
        }
      }

      .contact-submit {
        @include media-breakpoint-down(sm){
          @include make-col(10);
        }
        @include make-col(7);

        .form-control.btn-brand-secondary {
          font-family: $font-family-light;
          letter-spacing: 0.5px;
        }
      }

      .required {
        font-size: $font-size-small;
      }
    }
  }
}
