.jobs--wrapper {

  .job-content {
      a {
        color: $color-black;
        text-decoration: underline;

        li {
          list-style: none;
          padding-bottom: 10px;
      }
    }
  }
  
  &.highlight {
    background-color: #406761;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, .1607843137);
    padding: 15px;
    margin-bottom: 25px;
    
    h2 {
      margin-top: 0;
      color: white;
    }
    
    p, a {
      color: white !important;
    }
  }
}

table {
  &.jobs-content {
    a {
      color: $color-black;
      text-decoration: underline;
    }
  }
}

.jobposting {
  h1 {
    margin-bottom: 25px;
    
    strong {
      display: block;
      font-family: $font-family-regular;
      font-size: $font-size-h2;
      margin-top: 10px;
      line-height: 1.8rem;
    }
  }
}