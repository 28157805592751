.highlight {
    .highlight-image {
        height: auto;
        min-height: 330px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        @include media-breakpoint-down(md) {
            min-height: 260px;
        }

        @include media-breakpoint-down(sm) {
            background-position: center;
            min-height: 180px;
        }

        @media (max-width: 320px) {
            min-height: 140px;
        }
    }

    .highlight-caption {
        background-color: rgba($color: #000000, $alpha: 0.7);
        color: white;
        padding: 20px 30px;
        width: 63%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);


        @include media-breakpoint-down(lg) {
            height: 100%;
        }

        @include media-breakpoint-down(md) {
            position: relative;
            top: auto;
            transform: unset;
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            padding: 30px 15px;
        }

        h2 {
            font-size: 25px;
            color: white;
            margin-top: 0;
        }

        h3 {
            color: #3A9F55;
            font-size: 31px;
            font-family: $font-family-regular;
            margin-top: 10px;
            margin-bottom: 18px;
        }

         p {
             margin-bottom: 20px;
         }

         .btn-brand-primary {
             background-color: $color-green-refrigeration;
             display: inline-block;
             line-height: 20px;

             &:hover {
                background-color: darken($color-green-refrigeration, 4%);
              }
         }
    }
}
