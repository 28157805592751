.teaser-info {
  @extend .col;
  @extend %responsivnes;
  padding: 0 15px 40px 15px;

  .teaser-inner {
    padding: 15px 0;
    border-top: 1px solid $color-gray-medium-light;

    .teaser-content {
      @include make-col(12);

      h3 {
        font-size: $font-size-large * 1.1;
        font-family: $font-family-medium;
      }

      p {
        margin-bottom: 0;
      }
    }

    .teaser-button {
      @include media-breakpoint-down(sm){
        padding-top: 10px;
        display: block;
      }
      @include make-col(12);
      padding-top: 25px;
    }
  }
}
