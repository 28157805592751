.hermetic-nodetypes-bulletlist {
  @extend .row;

  .cols-3 {
    @include make-col(12);

    .bulletlist-conent {
      margin-right: 25px;
    }
  }

  .bulletlist-inner {
    display: flex;
  }

  .refrigeration {
    ul {
      li {
        background: url("../Images/layout/icon-refrigeration.png") 0 4px no-repeat;
        padding-left: 35px;
        margin-bottom: 10px;
        list-style-type: none;
      }
    }
  }

  .check {
    ul {
      li {
        background: url("../Images/layout/icon-check.png") 0 4px no-repeat;
        padding-left: 35px;
        margin-bottom: 10px;
        list-style-type: none;
      }
    }
  }
}
