.footer {
  @include make-col(12);
  padding: 34px 0 48px;
  min-height: 60px;
  background-color: #54555a;

  @include media-breakpoint-down(md) {
    align-items: center;
    padding-bottom: 25px;
  }

  .footer-content {
    padding: 0;

    @include media-breakpoint-up(lg) {
      @include make-col(12);
      display: flex;
      //   align-items: center;
    }
  }
}

.icons {
  @include make-col(4);

  @include media-breakpoint-down(md) {
    @include make-col(12);
  }

  display: block;
  padding: 0 15px;
}

.footer-social-media,
.spa-logo {
  display: flex;
  justify-content: flex-end;
}

.spa-logo {
  margin-right: 15px;
  margin-top: 20px;

  img {
    height: auto;
    width: 130px;
  }
}

.footer-social-media {
  a {
    img {
      margin-right: 20px;
      height: 29px;
      width: 29px;
      
      &.youtube {
        height: 28px;
        width: 44px;
      }

      @include media-breakpoint-down(sm) {
        height: 30px;
      }
    }
  }
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  @include make-col(8);

  @include media-breakpoint-up(md) {
    @include make-col(8);
  }

  @include media-breakpoint-down(md) {
    @include make-col(12);
  }

  padding: 0 15px;
  margin: 0;

  a {
    color: inherit;

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  .footer-nav-list {
    list-style-type: none;
    margin-bottom: 0;
    @include make-col(3);

    @include media-breakpoint-up(md) {
      display: block;
      align-items: center;
      padding-left: 15px;
      @include make-col(3);
    }

    @include media-breakpoint-down(md) {
      @include make-col(3);
      margin-right: 0;
      margin-bottom: 20px;
      padding-left: 6px;
    }

    @include media-breakpoint-down(sm) {
      @include make-col(6);
    }

    @media (max-width: 320px) {
      @include make-col(12);
    }

    li {
      @include media-breakpoint-down(sm) {
        margin-bottom: 2px;
      }

      margin-right: 30px;

      a {
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }

        color: $color-white;
        font-family: $font-family-light;
        font-size: 1rem;
      }
    }

    h4 {
      color: white;
      font-size: $font-size-medium;
      text-transform: uppercase;
      margin-top: 0;

      @include media-breakpoint-down(sm) {
        font-size: 1.15rem;
      }
    }
  }
}

.footer-language-menu {
  @include make-col(12);
  padding-left: 35px;

  @include media-breakpoint-up(sm) {
    @include make-col(8);
    padding-left: 15px;
    margin-left: 15px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(2);
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    margin-left: 0;
  }

  ul {
    display: flex;
    margin-bottom: 0;
    align-items: center;

    li {
      list-style: none;
      margin-right: 15px;

      &.flag-de a {
        display: inline-block;
        height: 15px;
        width: 25px;
        background: url("../Images/layout/de.png") no-repeat;
        background-size: 25px;
      }

      &.flag-en a {
        display: inline-block;
        height: 15px;
        width: 25px;
        background: url("../Images/layout/en.png") no-repeat;
        background-size: 25px;
      }

      &.flag-es {
        background: url("../Images/layout/es.png") no-repeat;
        background-size: 25px;
      }

      &.flag-fr {
        background: url("../Images/layout/fr.png") no-repeat;
        background-size: 25px;
      }
    }
  }
}
