@use "sass:math";

.header-nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  background-color: $color-gray;

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
    height: 38px;
  }

  a {
    color: inherit;
    font-size: 1rem;
    letter-spacing: 1px;

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  li {
    &.active > a,
    &.current > a {
      font-weight: 500;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .second-level-sub-navigation {
          display: flex;
        }
      }
    }
  }

.header-nav-content {
  position: relative;
  width: 100%;
//   display: flex;
  flex-wrap: wrap;
  z-index: 1000;

  &.collapse.show {
    @include media-breakpoint-down(md) {
        height: calc(100vh - 100px);
        overflow-y: scroll;
        background-color: white;
      }
  }

  .meta-nav {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
  }

  .header-nav-list {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      z-index: 1000;
      left: 0;
    }

    display: flex;
    padding: 0;
    margin-bottom: 0;

    li {
      position: relative;
      padding: 0;
      min-height: 45px;
      border-bottom: 1px solid $color-white;
      align-items: center;
      padding: 10px 0;

      a {
        color: $color-white;
        padding: 0 15px;
      }

      @include media-breakpoint-up(lg) {
        padding: auto;
        min-height: 30px;
        border-bottom: none;
        padding: 0;

        a {
          padding: 0 2px;
        }
      }
    }

    @include media-breakpoint-up(lg) {

      > li {
        display: flex;
        justify-content: center;
      }
    }

    &.primary {
      @include media-breakpoint-down(md){
        position: relative;
        @include make-col(12);
      }

      li {
        text-align: left;
        background-color: $brand-primary;
        margin-right: 0;
        text-transform: uppercase;

        &:hover {
          background-color: $brand-primary;

          ul {

            li {
              background-color: transparent;

              a {
                color: $color-gray;

                &:hover {
                  color: $brand-primary;
                }
              }
            }
          }
        }

        &.active, &.current {
          background-color: $color-gray-medium;
        }

        @include media-breakpoint-up(lg) {
          text-align: center;
          min-width: 120px;
          margin-right: 2px;

          &:hover {
            background-color: $color-gray-dark;

            a {
              color: $color-white;
            }

            ul {
              li {
                background-color: transparent;
                text-align: left;

                a {
                  color: $color-gray;

                  &:hover {
                    background-color: transparent;
                    color: $brand-primary;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.secondary {
      @include media-breakpoint-down(md){
        position: relative;
        @include make-col(12);
      }

      @include media-breakpoint-up(lg) {
        padding-left: 15px;
      }

      > li {
        background-color: $color-gray;
        text-align: left;
        padding-left: 0;
        margin-right: 0;

        @include media-breakpoint-up(lg) {
          margin-right: 10px;
          padding-left: 10px;

          &:first-child,
          &:nth-child(3),
          &:nth-child(4),
          &:last-child {
            ul {
              display: none;
            }
          }

          a {
            &:hover {
              color: $color-black;
            }
          }
        }

        ul {

          li {
            background-color: transparent;

            a {
              color: $color-gray;

              &:hover {
                background-color: transparent;
                color: $brand-primary;
              }
            }
          }
        }
      }
    }

    &.tertiary {
        @include media-breakpoint-up(lg) {
            display: none;
        }

        @include media-breakpoint-down(md) {
            box-shadow: 0 6px 12px rgba(0,0,0,.175);
        }

        li {
            background-color: white;
            border-bottom: 1px solid $color-gray-medium;

            &:last-child {
                border-bottom: 0;
            }

            a {
                color: black;
                &::after {
                    color: $color-gray-medium;
                }
            }
        }

        ul {
            box-shadow: unset;

            li {
              background-color: white;

              a {
                color: $color-gray;

                &:hover {
                  background-color: transparent;
                  color: $brand-primary;
                }
              }
            }
          }
    }
  }

  .second-level-sub-navigation {
    @include media-breakpoint-down(md) {
      position: relative;
    }

    height: auto;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    padding: 0 math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 2);
    font-size: $font-size-small;
    width: 320px;
    background-color: $color-white;
    border: 0;
    text-align: left;
    z-index: 1000;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    background-clip: padding-box;
    margin-top: 0;

    @include media-breakpoint-down(md) {
        box-shadow: none;
    }

    li {
      @include make-col(12);
      background-color: $color-white;
      padding-right: 5px;
      text-align: left;
      color: $color-gray;
      border: 0 solid #cacaca;
      border-bottom-width: 1px;
      padding: 10px 0;

      &:last-child {
        border-bottom: none;

        @include media-breakpoint-up(lg) {
          border-bottom: 1px solid #cacaca;
        }
      }

      a {
        font-size: $font-size-small;
        color: $color-gray;
        padding: 3px 20px;
        display: inline-block;
      }

      &:hover {

        a {
          color: $brand-primary;
        }
      }
    }
  }
}

.header-nav-list,
.second-level-sub-navigation {
  list-style-type: none;
}

.header-nav-toggle {

    @include media-breakpoint-up(lg) {
        display: none;
    }
    color: #000;

    button {
        outline: none;
    }
}

@media (min-width: $grid-float-breakpoint) {
  .header-nav-content {
    display: flex;
    visibility: visible;
    height: auto;
    overflow: visible;
  }
}
}
