.neos-nodetypes-headline {
    &.green {
        h1,
        h2,
        h3,
        h4 {
            color: $brand-primary;
        }
    }

    &.h1 {
        h2,
        h3 {
            @extend h1;
        }

        &.green {
            h1,
            h2,
            h3,
            h4 {
                color: $brand-primary;
            }
        }
    }

    &.no-margin-bottom {
        h1,
        h2,
        h3 {
            margin-bottom: 0;
        }
    }

    &.larger-and-light {
        h1,
        h2,
        h4,
        h4 {
            font-family: $font-family-light;
            font-size: 24px;
        }
    }
}

.neos-nodetypes-image {
    img {
        max-width: 100%;
        height: 100%;
    }

    figcaption {
        p {
            font-style: italic;
        }
    }

    &.application {
        @include make-col(4);
        padding-right: 9px;
        margin-top: 0;
        display: inline-block;
        position: relative;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding-left: 0;
            padding-right: 0;
        }

        figure {
            position: relative;
            margin: auto;
            overflow: hidden;

            img {
                transition: all 0.2s;
                display: block;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }
            }

            figcaption {
                width: 100%;
                height: 100%;

                p {
                    font-style: normal;
                    background-color: rgba($color: #000000, $alpha: 0.6);
                    width: 110px;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    padding-left: 8px;
                    padding-right: 8px;
                    color: white;
                    left: 0;
                    right: 0;
                    top: 50px;
                    position: absolute;

                    @include media-breakpoint-down(md) {
                        top: 45px;
                    }

                    @include media-breakpoint-down(xs) {
                        top: 52px;
                    }
                }
            }
        }
    }
}

.instanode-headstart-thumbimage {
    @include media-breakpoint-down(sm) {
        width: 100%;
        height: 200px;
    }
    width: 215px;
    height: 150px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: auto;
    }
}

.jonnitto-prettyembed {
    margin-bottom: 10px;
}

.neos-nodetypes-text {
    ul {
        list-style: none;
        li {
            &::before {
                content: "\25AA";
                font-weight: bold;
                display: inline-block;
                width: 16px;
                margin-left: -16px;
            }
        }
    }
}

.newsletter {
    // background-color: #DEDEDF;
    .newsletters-headline {
        h2 {
            margin-top: 0;
            margin-bottom: 5px;
            text-align: left;

            font-family: Vialog LT W01 Light, Tahoma, Arial, sans-serif;
            font-style: normal;
            font-variant: normal;
            font-weight: 300;
            font-size: 30px;
            line-height: 35.2px;

            letter-spacing: 0.3px;
            color: #212529;

            strong {
                font-weight: 500;
            }
        }
        h3 {
            margin-top: 0;
            margin-bottom: 0px;
            text-align: left;

            font-family: Vialog LT W01 Light, Tahoma, Arial, sans-serif;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            padding-top: 3px;

            letter-spacing: 0.4px;
            color: #464646;
        }
    }
    .neos-nodetypes-html {
        iframe {
            height: 160px;
            @media (max-width: 1145px) {
                height: 235px;
            }
            @media (max-width: 991px) {
                height: 255px;
            }
            @media (max-width: 882px) {
                height: 310px;
            }
            @media (max-width: 767px) {
                height: 340px;
            }
            @media (max-width: 510px) {
                height: 385px;
            }

            @media (max-width: 407px) {
                height: 410px;
            }
        }
    }
}
